module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HYPERCONNECT®","short_name":"HYPERCONNECT®","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icons":[{"src":"favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"favicon/apple-icon-precomposed.png","sizes":"180x180","type":"image/png"},{"src":"favicon/apple-icon.png","sizes":"180x180","type":"image/png"},{"src":"favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/ms-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/ms-icon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"favicon/ms-icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"favicon/ms-icon-70x70.png","sizes":"70x70","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JB3NMB"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/save/src/intl","languages":["ko","en"],"defaultLanguage":"ko","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
