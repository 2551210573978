import { navigate } from "gatsby";

export const onInitialClientRender = () => {
  const savedLanguage = localStorage.getItem("gatsby-intl-language");
  if (!savedLanguage) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith("ko")) {
      localStorage.setItem("gatsby-intl-language", "ko");
      if (window.location.pathname !== "/ko/") {
        navigate("/ko/");
      }
    } else {
      localStorage.setItem("gatsby-intl-language", "en");
      if (window.location.pathname !== "/en/") {
        navigate("/en/");
      }
    }
  }
};
